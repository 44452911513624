import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { Navigator } from 'src/components/ScanData/Navigator';
import { FormControlLabel, Stack, Checkbox, Autocomplete, CircularProgress, FormControl, InputLabel, Select, MenuItem, FormHelperText, Switch } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { removeExtraSpaces } from 'src/utils/validators';
import { LoadingButton } from '@mui/lab';
import { generateRequiredMsg } from 'src/utils/validMsgGenerator';
import { selectStores } from 'src/redux/Stores/selector';
import { useSelector } from 'react-redux';
import {
  fetchCountriesServices,
  fetchSetupDetailsServices,
  setupConnectionServices,
  successFTPConnectionServices,
  updateSetupConnectionServices,
} from 'src/services/SalesSubmit.Services';
import swal from 'sweetalert';
import Iconify from 'src/components/iconify';
import SimpleBackdrop from 'src/components/Backdrop';
import { RedDotLoader } from '../components/RedDotLoader';

export default function ScanDataSetup() {
  const navigate = useNavigate();
  const { stores, isLoading } = useSelector(selectStores);
  const { id } = useParams();
  const store = stores.find((store) => store.id === id);

  const [successConnection, setSuccessConnection] = useState(false);
  const [update, setUpdate] = useState(false);
  const [successConnectionLoader, setSuccessConnectionLoader] = useState(false);
  const [countryLoader, setCountryLoader] = useState(false);
  const [countries, setCountries] = useState([]);
  const [scanDataSwitch, setScanDataSwitch] = useState(true);
  const [setupConnectionLoader, setSetupConnectionLoader] = useState(false);

  const navigateTo = (url = '') => {
    navigate(`/scandata/${id}${url}`);
  };

  const [setupDetails, setSetupDetails] = useState({
    storeName: '',
    street: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    accNo: '',
  });

  const [setupDetailsErr, setSetupDetailsErr] = useState({
    storeName: false,
    street: false,
    city: false,
    state: false,
    country: false,
    zipCode: false,
    accNo: false,
  });

  const [showPassword, setShowPassword] = React.useState(false);

  const [FTPDetails, setFTPDetails] = useState({
    server: '',
    name: '',
    password: '',
    connectionType: 'FTP',
  });

  const [FTPDetailsErr, setFTPDetailsErr] = useState({
    server: false,
    name: false,
    password: false,
    connectionType: false,
  });

  const [submitReportArr, setSubmitReportArr] = useState([]);
  const [submitReportDay, setSubmitReportDay] = useState(0);

  const handleChangeSetup = (e, prop) => {
    if (prop === 'accNo'){
      setSetupDetails({
        ...setupDetails,
        [prop]: e
      });
      setSetupDetailsErr({
      ...setupDetailsErr,
      [prop]: !removeExtraSpaces(e),
    });
    }
    else if (prop === "country"){
      setSetupDetails({
        ...setupDetails,
        [prop]: e
      });
      setSetupDetailsErr({
      ...setupDetailsErr,
      [prop]: !removeExtraSpaces(e),
    });
    }
    else{
    setSetupDetails({
      ...setupDetails,
      [prop]: e.target.value,
    });
    setSetupDetailsErr({
      ...setupDetailsErr,
      [prop]: !removeExtraSpaces(e.target.value),
    });
  }
  };

  const handleSubmitReports = (id, checked) => {
    if (!checked) {
      const arr = submitReportArr;
      const index = arr.indexOf(Number(id));
      if (index > -1) {
        arr.splice(index, 1);
      }
      setSubmitReportArr([...arr]);
    } else {
      setSubmitReportArr((currentArr) => [...currentArr, Number(id)]);
    }
  };

  const handleChangeFTP = (e, prop) => {
    setSuccessConnection(false);
    setFTPDetails({
      ...FTPDetails,
      [prop]: e.target.value,
    });
    setFTPDetailsErr({
      ...FTPDetailsErr,
      [prop]: !e.target.value,
    });
  };

  const onSubmitSetup = async () => {
    const { storeName, street, city, state, country, accNo, zipCode } = setupDetails;
    try {
      if (
        removeExtraSpaces(storeName) &&
        removeExtraSpaces(street) &&
        removeExtraSpaces(city) &&
        removeExtraSpaces(state) &&
        removeExtraSpaces(country) &&
        removeExtraSpaces(zipCode) &&
        (store?.Name === "PMUSA" ?  removeExtraSpaces(accNo) : true)
      ) {
        setSetupConnectionLoader(true);
        const response = update
          ? await updateSetupConnectionServices(setupDetails, FTPDetails, id, submitReportArr, submitReportDay, scanDataSwitch)
          : await setupConnectionServices(setupDetails, FTPDetails, id, submitReportArr, submitReportDay, scanDataSwitch);
        if (response.status === 201 || response.status === 200) {
          setSetupConnectionLoader(false);
          swal('Success', response.data.message, 'success').then(() => window.location.reload());
          setSuccessConnection(true);
        }
      } else {
        setSetupDetailsErr({
          storeName: removeExtraSpaces(storeName) === '',
          street: removeExtraSpaces(street) === '',
          city: removeExtraSpaces(city) === '',
          country: removeExtraSpaces(country) === '',
          zipCode: removeExtraSpaces(zipCode) === '',
          accNo: store?.Name === "PMUSA" && (removeExtraSpaces(accNo) === '' || removeExtraSpaces(accNo) === 0) ? true : false,
          state: removeExtraSpaces(state) === '',
        });
      }
    } catch (err) {
      console.log(err);
      setSetupConnectionLoader(false);
    }
  };

  const onSubmitFTP = async () => {
    try {
      if (
        removeExtraSpaces(FTPDetails.server) &&
        removeExtraSpaces(FTPDetails.name) &&
        removeExtraSpaces(FTPDetails.password) && 
        FTPDetails?.connectionType
      ) {
        setSuccessConnectionLoader(true);
        const response = await successFTPConnectionServices(FTPDetails);
        if (response.status === 201) {
          setSuccessConnectionLoader(false);
          swal('Success', response.data.message, 'success');
          setSuccessConnection(true);
        }
      } else {
        setFTPDetailsErr({
          server: removeExtraSpaces(FTPDetails.server) === '',
          name: removeExtraSpaces(FTPDetails.name) === '',
          connection_type: FTPDetails?.connectionType === "FTP",
          password: removeExtraSpaces(FTPDetails.password) === '',
        });
      }
    } catch (err) {
      setSuccessConnectionLoader(false);
      swal('Error', err.response.data.message, 'error');
      setSuccessConnection(false);
    }
  };

  React.useEffect(() => {
    const fetchSetupDetails = async () => {
      try {
        setSetupConnectionLoader(true);
        const response = await fetchSetupDetailsServices(id);
        if (response.status === 200) {
          const { data } = response.data;
          setSetupDetails({
            storeName: data?.storeName || '',
            street: data?.street || '',
            city: data?.city || '',
            state: data?.state || '',
            country: data?.country || '',
            zipCode: data?.zip || '',
            accNo: data?.accountNumber || '',
          });
          if (data?.submitWithHeader !== null){
          setScanDataSwitch(data?.submitWithHeader);
          }
          setSubmitReportDay(data?.submitReportOn || 0);
          setUpdate(true);
          setFTPDetails({
            server: data?.serverName || '',
            name: data?.userName || '',
            connectionType: data?.connectionProtocol || "",
            password: data?.password || '',
          });
          setSuccessConnection(true);
          if (data?.reportSubmitTo?.length > 1) {
            setSubmitReportArr(data?.reportSubmitTo?.split(', ').map(i => Number(i)) || []);
          } else if(data?.reportSubmitTo?.length === 1) {
            setSubmitReportArr([Number(data?.reportSubmitTo)]);
          }
          setSetupConnectionLoader(false);
        }
      } catch (err) {
        console.log(err);
        setSetupConnectionLoader(false);
      }
    };
    if (id) {
      fetchSetupDetails();
    }
  }, [id]);


  React.useEffect(() => {
    const fetchCountries = async () => {
      try {
        setCountryLoader(true);
        const response = await fetchCountriesServices();
        if (response.status === 200) {
          const { data } = response.data;
          setCountries(data || []);
          setCountryLoader(false);
        }
      } catch (err) {
        setCountryLoader(false);
        console.log(err);
      }
    };
      fetchCountries();
  }, []);

  return (
    <>
      <Helmet>
        <title> Scan Data | Retailz POS </title>
      </Helmet>
      <SimpleBackdrop open={setupConnectionLoader} />
      {isLoading ? (
        <RedDotLoader />
      ) : (
          <Container maxWidth="xl" style={{ margin: 'auto', marginTop: '-30px' }}>
          <Typography variant="h6">
            {store?.Name}
          </Typography>
          <Navigator navigateTo={navigateTo} title={id} />
          <br />
          <fieldset className="add_sales_fieldSet">
            <legend className="add_sales_fieldSet_legend">Setup</legend>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              justifyContent={{ xs: 'center', md: 'space-between' }}
            >
              <Stack direction={'column'} width={'100%'} spacing={2}>
                <fieldset className="add_sales_fieldSet">
                  <legend className="add_sales_fieldSet_legend_outlined">Store Information</legend>
                  <Stack direction={'column'} style={{ width: '100%' }} spacing={2}>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                      <TextField
                        color="primary"
                        label="Store Name"
                        disabled={update}
                        value={setupDetails?.storeName || ''}
                        style={{ width: '100%' }}
                        onChange={(e) => handleChangeSetup(e, 'storeName')}
                        error={setupDetailsErr.storeName}
                        size="small"
                        helperText={setupDetailsErr.storeName && generateRequiredMsg('Store Name')}
                      />
                      <TextField
                        color="primary"
                        label="Street"
                        disabled={update}
                        value={setupDetails?.street || ''}
                        style={{ width: '100%' }}
                        size="small"
                        onChange={(e) => handleChangeSetup(e, 'street')}
                        error={setupDetailsErr.street}
                        helperText={setupDetailsErr.street && generateRequiredMsg('Street')}
                      />
                      <TextField
                        color="primary"
                        label="City"
                        size="small"
                        disabled={update}
                        value={setupDetails?.city || ''}
                        style={{ width: '100%' }}
                        onChange={(e) => handleChangeSetup(e, 'city')}
                        error={setupDetailsErr.city}
                        helperText={setupDetailsErr.city && generateRequiredMsg('City')}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                      <TextField
                        color="primary"
                        label="State"
                        size="small"
                        disabled={update}
                        value={setupDetails?.state || ''}
                        style={{ width: '100%' }}
                        onChange={(e) => handleChangeSetup(e, 'state')}
                        error={setupDetailsErr.state}
                        helperText={setupDetailsErr.state && generateRequiredMsg('State')}
                      />
                      <Autocomplete
                          id="virtualize-demo"
                          sx={{ width: '100%' }}
                          options={countries || []}
                          disabled={update}
                          size="small"
                          disableClearable
                          value={
                            countries?.find((option) => option.id === Number(setupDetails?.country))
                              || { Name: '' }
                          }
                          getOptionLabel={(option) => option.Name}
                          onChange={(e, v) => handleChangeSetup(v.id, 'country') }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Country"
                              placeholder="Country"
                              error={setupDetailsErr.country}
                              helperText={setupDetailsErr.country && generateRequiredMsg('Country')}
                            />
                          )}
                        />
                      <TextField
                        color="primary"
                        label="Zip Code"
                        size="small"
                        disabled={update}
                        value={setupDetails?.zipCode || ''}
                        style={{ width: '100%' }}
                        onChange={(e) => handleChangeSetup(e, 'zipCode')}
                        error={setupDetailsErr.zipCode}
                        helperText={setupDetailsErr.zipCode && generateRequiredMsg('Zip Code')}
                      />
                      <TextField
                        color="primary"
                        size="small"
                        label="Account No."
                        value={setupDetails?.accNo || ''}
                        inputProps={{
                          maxLength: 6
                        }}
                        style={{ width: '100%' }}
                        onChange={(e) => handleChangeSetup(Number(e.target.value.replace(/\D/g, '')), 'accNo')}
                        error={ store?.Name === "PMUSA" ? setupDetailsErr.accNo : false}
                          helperText={store?.Name === "PMUSA" ? setupDetailsErr.accNo && generateRequiredMsg('Account No.'): ""}
                      />
                    </Stack>
                  </Stack>
                </fieldset>
                <Stack width={'100%'}>
                  <fieldset className="add_sales_fieldSet">
                    <legend className="add_sales_fieldSet_legend_outlined">{FTPDetails.connectionType || "Connection"} Credentials</legend>
                    <Stack direction={'column'} style={{ width: '100%' }} spacing={2}>
                      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems={'baseline'}>
                          <FormControl color="primary" error={FTPDetailsErr.connectionType} style={{ width: '100%' }}>
                              <InputLabel id="demo-multiple-checkbox-label" size="small">
                                Connection Protocol
                              </InputLabel>
                              <Select
                                value={FTPDetails?.connectionType}
                              label="Connection Protocol"
                                size="small"
                              error={FTPDetailsErr.connectionType}
                                onChange={(e) => handleChangeFTP(e, 'connectionType')}
                              >
                                <MenuItem value={'FTP'}>FTP</MenuItem>
                                <MenuItem value={'SFTP'}>SFTP</MenuItem>
                              </Select>
                            {FTPDetailsErr.connectionType && <FormHelperText>{generateRequiredMsg('Connection Type')}</FormHelperText>}
                            </FormControl>
                        <TextField
                          color="primary"
                          size="small"
                          label="Server Host / Name"
                          value={FTPDetails?.server || ''}
                          style={{ width: '100%' }}
                          onChange={(e) => handleChangeFTP(e, 'server')}
                          error={FTPDetailsErr.server}
                          helperText={FTPDetailsErr.server && generateRequiredMsg('Server Host / Name')}
                        />
                        <TextField
                          color="primary"
                          label="User Name"
                          size="small"
                          value={FTPDetails?.name || ''}
                          style={{ width: '100%' }}
                          onChange={(e) => handleChangeFTP(e, 'name')}
                          error={FTPDetailsErr.name}
                          helperText={FTPDetailsErr.name && generateRequiredMsg('User Name')}
                        />
                        <TextField
                          name="password"
                          label="Password"
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          value={FTPDetails?.password || ''}
                          style={{ width: '100%' }}
                          onChange={(e) => handleChangeFTP(e, 'password')}
                          error={FTPDetailsErr.password}
                          helperText={FTPDetailsErr.password && generateRequiredMsg('Password')}
                        />
                        <LoadingButton
                          onClick={onSubmitFTP}
                          style={{ width: '70%', maxHeight: '30px', borderRadius: '3px', fontSize: '12px' }}
                          size="small"
                          loading={successConnectionLoader}
                          variant={'contained'}
                          color={'primary'}
                        >
                          Test {FTPDetails.connectionType} Connection
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </fieldset>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <fieldset className="add_sales_fieldSet">
                    <legend className="add_sales_fieldSet_legend_outlined">Submit Reports</legend>
                      <Stack direction={"row"} justifyContent={"space-between"} >
                      
                      <FormControl color="primary" error={FTPDetailsErr.connectionType} style={{ width: '100%', maxWidth: '200px' }}>
                        <InputLabel id="demo-multiple-checkbox-label" size="small">
                          Submit Report On
                        </InputLabel>
                        <Select
                          value={submitReportDay}
                          label="Submit Report On"
                          size="small"
                          // error={FTPDetailsErr.connectionType}
                          onChange={(e) => setSubmitReportDay(e.target.value)}
                        >
                          <MenuItem value={0}>Sunday</MenuItem>
                          <MenuItem value={1}>Monday</MenuItem>
                          <MenuItem value={2}>Tuesday</MenuItem>
                          <MenuItem value={3}>Wednesday</MenuItem>
                          <MenuItem value={4}>Thursday</MenuItem>
                          <MenuItem value={5}>Friday</MenuItem>
                          <MenuItem value={6}>Saturday</MenuItem>
                        </Select>
                        {FTPDetailsErr.connectionType && <FormHelperText>{generateRequiredMsg('Connection Type')}</FormHelperText>}
                      </FormControl>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={scanDataSwitch}
                              inputProps={{ 'aria-label': 'controlled' }}
                              onChange={(e) => {
                                setScanDataSwitch(e.target.checked);
                              }}
                            />
                          }
                          label="Submit report with headers"
                        />

                      </Stack>
                      <FormHelperText>
                        <strong>Note:</strong><br/>
                        If you select &quot;Submit Report on Saturday&quot;, the report will be generated on Sunday with data from the previous Sunday to that Saturday.
                        <br />
                        <strong>For example, </strong>Suppose, 1st day of the current month is Saturday, and you have also chose to submit your report on the Saturday.
                        <br/>So, your report will be submitted on the Sunday 9th at 12:00 AM for the date range of 2nd - 8th.
                      </FormHelperText>
                      {/* <br/> */}
                    {stores?.filter((item) => item.id !== id).length === 0 && (
                      <Typography textAlign={'center'}>No data found. </Typography>
                    )}
                    {stores
                      ?.filter((item) => item.id !== id)
                      ?.map((store) => (
                        <FormControlLabel
                          key={store.id}
                          style={{ marginRight: '20px' }}
                          onChange={(e) => handleSubmitReports(store.id, e.target.checked)}
                          control={<Checkbox checked={submitReportArr.indexOf(Number(store.id)) !== -1} />}
                          label={`Submit to ${store.Name}`}
                        />
                      ))} 
                      
                  </fieldset>
                </Stack>
              </Stack>
            </Stack>
          </fieldset>
          <div className="save_button_div">
            <LoadingButton
              size="small"
              type="button"
              disabled={!successConnection}
              onClick={onSubmitSetup}
              variant="contained"
              color="primary"
              style={{ color: 'white', borderRadius: '3px' }}
              loading={setupConnectionLoader}
            >
              {update ? 'Update' : 'Done'}
            </LoadingButton>
          </div>
        </Container>
      )}
    </>
  );
}
