import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import Iconify from '../iconify';
import debounce from 'lodash.debounce';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 350, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

SearchBarMui.propTypes = {
  numSelected: PropTypes.number,
  onFilterName: PropTypes.func,
  title: PropTypes.string,
};

export default function SearchBarMui({ onSubmitHandler, keyword, setKeyword, title, disabled=false, height = '65px' }) {
  const [value, setValue] = React.useState('');

  // highlight-starts
  const debouncedSave = React.useCallback(
    debounce((nextValue) => {
      onSubmitHandler(nextValue);
      setKeyword(nextValue);
    }, 600),
    [] // will be created only once initially
  );
  // highlight-ends

  const handleChange = (event) => {
    const { value: nextValue } = event.target;
    setValue(nextValue.trim());
    // Even though handleChange is created on each render and executed
    // it references the same debouncedSave that was created initially
    debouncedSave(nextValue.trim());
  };

  React.useEffect(() => {
    if (keyword === undefined) {
      setValue('');
    }
  }, [keyword]);
  return (
    <RootStyle
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitHandler(keyword);
      }}
      style={{ height: height, minHeight: height }}
    >
      <SearchStyle
        onChange={handleChange}
        size="small"
        style={{ borderRadius: '30px', fontSize: '14px' }}
        disabled={disabled}
        placeholder={`Search ${title}...`}
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />
    </RootStyle>
  );
}
