import React from 'react';

const activeBtn = {
  backgroundColor: '#A81A31',
  color: 'white',
  // borderRadius: '3px'
};
const inactiveBtn = {
  backgroundColor: '#EEEBEB',
  color: 'black',
  // borderRadius: '3px'
};

const Tabs = ({
  lists = [],
  setValue,
  value=0,
}) => {
  return (
    <div className="tabs-flex">
      {lists.map((list) => (
        <div
          className={ list.disabled ? "custom-tab-disabled" : "custom-tab"}
          style={value === list.value ? activeBtn : inactiveBtn}
          key={list.id}
          onClick={() => {
            if (!list.disabled) setValue(list.value);
          }}
        >
              <img src={value === list.value ? list.active : list.inactive} style={{ width: '15px', height: '15px' }} />  {list.title}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
